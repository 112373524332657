<template>
  <el-button @click="submitForm" class="btn-blue" type="primary" :disabled="isDisabled" data-testid="continue">
    {{ $t('common.button.continue') }}{{ amount ? ` (${accountCurrency}${formatDepositAmount})` : '' }}
  </el-button>
</template>
<script>
export default {
  name: 'qrcode-button',
  props: {
    amount: { type: [String, Number] },
    accountCurrency: { type: String },
    amountPresition: { type: Number, default: 2 },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formatDepositAmount() {
      return Number.parseFloat(this.amount).toFixed(this.amountPresition);
    }
  },
  methods: {
    submitForm() {
      this.$emit('submitForm');
    }
  }
};
</script>
